* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul.MuiMenu-list {
    max-height: 368px;
}

body
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #4039c6;
}

body .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #4039c6;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: #4039c60a;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover {
    background-color: #eff0fb;
}

body
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #4039c6;
}

body .MuiButtonBase-root.MuiMenuItem-root.Mui-focusVisible {
    background-color: #4039c60a;
}

body .MuiTextField-root > div.Mui-disabled {
    background: rgba(0, 0, 0, 0.1);
}

span.red {
    color: red;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_f6e87d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_f6e87d';src: local("Arial");ascent-override: 92.49%;descent-override: 24.34%;line-gap-override: 0.00%;size-adjust: 100.30%
}.__className_f6e87d {font-family: '__Roboto_f6e87d', '__Roboto_Fallback_f6e87d';font-weight: 400;font-style: normal
}

